/**
 * On document ready.
 */
$(document).ready(function () {

    /**
     * @param  {object} '.js-dealer_signup-loading'
     */
    var $loading = $('.js-dealer_signup-loading');

    /**
     * @param  {object} '.js-dealer_signup-success'
     */
    var $success = $('.js-dealer_signup-success');

    /**
     * @param  {string}
     */
    var classLoadingActive = 'modal-body__loading--active';

    /**
     * @param  {string}
     */
    var classSuccessActive = 'modal-body__success--active';

    /**
     * @param  {string}
     */
    var inputErrorClass = 'input-error';

    /**
     * @param  {string}
     */
    var subscribeRoute = $('.js-dealer_signup_subscribe-route').val();

    /**
     * Validate email.
     *
     * @param  {string} email
     */
    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
     * @param  {object} $el
     */
    function setInputError($el){
        $el.parent().addClass(inputErrorClass);
    }

    /**
     * Sets loading.
     *
     * @param {boolean} status
     */
    function setLoading(status) {
        if (status) {
            $loading.addClass(classLoadingActive);
        } else {
            $loading.removeClass(classLoadingActive);
        }
    }

    /**
     * Sets success.
     *
     * @param {boolean} status
     */
    function setSuccess(status) {
        if (status) {
            $success.addClass(classSuccessActive);
        } else {
            $success.removeClass(classSuccessActive);
        }
    }

    /**
     * Submit form function.
     */
    $('body').on('click', '.js-dealer_signup_subscribe-button', function () {

            // Show loading
            setLoading(true);

            var error = 0;

            if(!$('#salesforce_contact_email').val()) {
                setInputError($('#salesforce_contact_email'));
                error = error + 1;
            }
            if(!validateEmail($('#salesforce_contact_email').val())) {
                setInputError($('#salesforce_contact_email'));
                error = error + 1;
            }
            if(!$('#salesforce_contact_first_name').val()) {
                setInputError($('#salesforce_contact_first_name'));
                error = error + 1;
            }
            if(!$('#salesforce_contact_last_name').val()) {
                setInputError($('#salesforce_contact_last_name'));
                error = error + 1;
            }
            /*
            if(!$('#salesforce_contact_phone').val()) {
                setInputError($('#salesforce_contact_phone'));
                error = error + 1;
            }
            if(!$('#salesforce_contact_company').val()) {
                setInputError($('#salesforce_contact_company'));
                error = error + 1;
            }
            if(!$('#salesforce_contact_zip').val()) {
                setInputError($('#salesforce_contact_zip'));
                error = error + 1;
            }
            */
            if(!$('#salesforce_contact_state').val()) {
                setInputError($('#salesforce_contact_state'));
                error = error + 1;
            }
            if(!$('#salesforce_contact_country').val()) {
                setInputError($('#salesforce_contact_country'));
                error = error + 1;
            }
            /*
            if(!$('#salesforce_contact_00N1U00000U30Zq').val().length) {
                setInputError($('#salesforce_contact_00N1U00000U30Zq'));
                error = error + 1;
            }
            */
            if(!$('#salesforce_contact_00N1U00000U30a0').val().length) {
                setInputError($('#salesforce_contact_00N1U00000U30a0'));
                error = error + 1;
            }
            /*
            if(!$('#salesforce_contact_00N1U00000U30Zv').val()) {
                setInputError($('#salesforce_contact_00N1U00000U30Zv'));
                error = error + 1;
            }
            */
            if(!$('#salesforce_contact_terms').prop('checked')) {
                setInputError($('#salesforce_contact_terms'));
                error = error + 1;
            }
            if(error > 0) {
                $('.modal-footer-msg').text('Please, check form for errors');
                setLoading(false);
                return;
            }
            $('.js-dealer_signup_subscribe-button').attr('disabled', true);
            $('.js-dealer_signup_subscribe-button').text('Loading...');

        //var formDataSend = new FormData($(this))

            // Ajax request
            $.ajax({
                type: 'POST',
                url: subscribeRoute,
                data: $('#sf-contact').serialize(),
                accept: 'application/json',
            }).done(function(data) {
                var rep = data;
                if(rep.ok === true) {
                    setLoading(false);
                    setSuccess(true);
                    $('.modal-footer-msg').text(rep.message);
                    $('.demo-form-full').html('Thank you for your submission. We will contact you shortly.')
                    $('.js-dealer_signup_subscribe-button').hide();
                    window.location.replace("/content/schedule-a-demo-thank-you/");
                } else {
                    $('.modal-footer-msg').text(rep.message);
                    setLoading(false);
                    $('.js-dealer_signup_subscribe-button').attr('disabled', false);
                    $('.js-dealer_signup_subscribe-button').text('Submit');
                    grecaptcha.reset();
                    if(rep.repeat !== true) {
                        //$('.js-dealer_signup_subscribe-button').hide();
                    }
                }
            }).fail(function () {
                // Hide loading
                setLoading(false);
                $('.js-dealer_signup_subscribe-button').attr('disabled', false);
                $('.js-dealer_signup_subscribe-button').text('Submit');
            });
        });

    /**
     * Inits events.
     */
    function initEvents() {
        //$formSubmitBtn.on('click', submitForm);
    }

    /**
     * Init component.
     */
    function init() {
        initEvents();
    }

    // Init
    init();
});