$( document ).ready(function() {
  /**
   * Gets search bar element.
   *
   * @type {*|jQuery|HTMLElement}
   */
  var $searchBar = $('.js-search-bar');

  /**
   * Gets close search bar button element.
   *
   * @type {*|jQuery|HTMLElement}
   */
  var $closeSearchBarBtn = $('.js-close-search');

  /**
   * Gets display search bar button element.
   *
   * @type {*|jQuery|HTMLElement}
   */
  var $displaySearchBarBtn = $('.js-display-search-bar');

  /**
   * Defines displayed search bar class.
   *
   * @type {string}
   */
  var classDisplayedSearchBar = 'c-navbar__search-bar--displayed';

  /**
   * Defines active search bar class.
   *
   * @type {string}
   */
  var classActiveSearchBar = 'active';

  /**
   * Show search bar.
   *
   * @param e
   */
  function showSearchBar(e){
    e.preventDefault();
    $searchBar.addClass(classDisplayedSearchBar);
    $displaySearchBarBtn.parent().addClass(classActiveSearchBar);
    $('#criteria_search_value').focus();
  }

  /**
   * Hide search bar.
   *
   * @param e
   */
  function hideSearchBar(e){
    e.preventDefault();
    $searchBar.removeClass(classDisplayedSearchBar);
    $displaySearchBarBtn.parent().removeClass(classActiveSearchBar);
  }

  /**
   * Init events.
   */
  function initEvents(){
    // Show search bar
    $displaySearchBarBtn.on('click', showSearchBar);

    // Hide search bar
    $closeSearchBarBtn.on('click', hideSearchBar);
  }

  /**
   * Init component
   */
  function init() {
    initEvents();
  }

  // Initialize script
  init();
});