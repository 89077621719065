/**
 * On document ready.
 */
$(document).ready(function () {
    /**
     * Gets loading element.
     *
     * @type {*|jQuery|HTMLElement}
     */
    var $loading = $('.js-loading');
    /**
     * Gets footer element.
     *
     * @type {*|jQuery|HTMLElement}
     */
    var $footer = $('.js-footer');
    /**
     * Gets upcoming events list element.
     *
     * @type {*|jQuery|HTMLElement}
     */
    var $upcomingEventsList = $('.js-upcoming_events-list');
    /**
     * Get no upcoming events message element.
     *
     * @type {*|jQuery|HTMLElement}
     */
    var $noUpcomingEventsMessage = $('.js-no-upcoming_events-message');
    /**
     * UpcomingEvents path.
     *
     * @type {*|jQuery}
     */
    var getUpcomingEventsPath = $('.js-get-upcoming_events-path').val();
    /**
     * Defines active loading class
     *
     * @type {string}
     */
    var classLoadingActive = 'c-upcoming_events__loading--active';
    /**
     * Defines shown class for no upcoming events message
     *
     * @type {string}
     */
    var classNoUpcomingEventsMessageShown = 'c-upcoming_events__no-upcoming_events-message--shown';
    /**
     * Defines current pagination page.
     *
     * @type {number}
     */
    var currentPaginationPage = 1;
    /**
     * Is scrolled flag.
     *
     * @type {boolean}
     */
    var isScrolled = false;
    /**
     * If has upcoming events.
     *
     * @type {boolean}
     */
    var hasUpcomingEvents = true;
    /**
     * Sets loading.
     *
     * @param {boolean} status
     */
    function setLoading(status) {
        if (status) {
            $loading.addClass(classLoadingActive);
        } else {
            $loading.removeClass(classLoadingActive);
        }
    }
    /**
     * Detect is user scrolled to bottom.
     *
     * @return {boolean}
     */
    function isScrolledToBottom() {
        return $(window).scrollTop() + $(window).height() >= $(document).height() - $footer.outerHeight();
    }
    /**
     * Append upcoming events data to upcoming events list.
     * @param data
     */
    function appendUpcomingEventsDataToUpcomingEventsList(data) {
        $upcomingEventsList.append(data);
    }
    /**
     * Show no upcoming events message
     */
    function showNoUpcomingEventsMessage() {
        $noUpcomingEventsMessage.addClass(classNoUpcomingEventsMessageShown);
    }
    /**
     * Gets upcoming events data.
     */
    function getUpcomingEventsData() {
        if (hasUpcomingEvents) {
            setLoading(true);
            if (!isScrolled) {
                isScrolled = true;
                currentPaginationPage++;
                $.ajax(getUpcomingEventsPath + '?page=' + currentPaginationPage)
                    .done(function (data) {
                        appendUpcomingEventsDataToUpcomingEventsList($(data));
                        isScrolled = false;
                        setLoading(false);
                    })
                    .fail(function (data) {
                        isScrolled = false;
                        setLoading(false);
                        if (data.status === 404) {
                            hasUpcomingEvents = false;
                            showNoUpcomingEventsMessage();
                        }
                    });
            }
        }
    }
    /**
     * Function that fires on scroll event.
     */
    function onScroll() {
        // If is scrolled to bottom
        if (isScrolledToBottom()) {
            // Get upcoming events data
            getUpcomingEventsData();
        }
    }
    /**
     * Inits events.
     */
    function initEvents() {
        $(window).on('scroll', onScroll);
    }
    /**
     * Init component.
     */
    function init() {
        initEvents();
    }
    // Init
    init();
});