/**
 * On document ready.
 */
$(document).ready(function () {
    /**
     * Gets loading element.
     *
     * @type {*|jQuery|HTMLElement}
     */
    var $loading = $('.js-loading');
    /**
     * Gets footer element.
     *
     * @type {*|jQuery|HTMLElement}
     */
    var $footer = $('.js-footer');
    /**
     * Gets case_studies list element.
     *
     * @type {*|jQuery|HTMLElement}
     */
    var $case_studiesList = $('.js-case_studies-list');
    /**
     * Get no case_studies message element.
     *
     * @type {*|jQuery|HTMLElement}
     */
    var $noCaseStudiesMessage = $('.js-no-case_studies-message');
    /**
     * CaseStudies path.
     *
     * @type {*|jQuery}
     */
    var getCaseStudiesPath = $('.js-get-case_studies-path').val();
    /**
     * Defines active loading class
     *
     * @type {string}
     */
    var classLoadingActive = 'c-case_studies__loading--active';
    /**
     * Defines shown class for no case_studies message
     *
     * @type {string}
     */
    var classNoCaseStudiesMessageShown = 'c-case_studies__no-case_studies-message--shown';
    /**
     * Defines current pagination page.
     *
     * @type {number}
     */
    var currentPaginationPage = 1;
    /**
     * Is scrolled flag.
     *
     * @type {boolean}
     */
    var isScrolled = false;
    /**
     * If has case_studies.
     *
     * @type {boolean}
     */
    var hasCaseStudies = true;
    /**
     * Sets loading.
     *
     * @param {boolean} status
     */
    function setLoading(status) {
        if (status) {
            $loading.addClass(classLoadingActive);
        } else {
            $loading.removeClass(classLoadingActive);
        }
    }
    /**
     * Detect is user scrolled to bottom.
     *
     * @return {boolean}
     */
    function isScrolledToBottom() {
        return $(window).scrollTop() + $(window).height() >= $(document).height() - $footer.outerHeight();
    }
    /**
     * Append case_studies data to case_studies list.
     * @param data
     */
    function appendCaseStudiesDataToCaseStudiesList(data) {
        $case_studiesList.append(data);
    }
    /**
     * Show no case_studies message
     */
    function showNoCaseStudiesMessage() {
        $noCaseStudiesMessage.addClass(classNoCaseStudiesMessageShown);
    }
    /**
     * Gets case_studies data.
     */
    function getCaseStudiesData() {
        if (hasCaseStudies) {
            setLoading(true);
            if (!isScrolled) {
                isScrolled = true;
                currentPaginationPage++;
                $.ajax(getCaseStudiesPath + '?page=' + currentPaginationPage)
                    .done(function (data) {
                        appendCaseStudiesDataToCaseStudiesList($(data));
                        isScrolled = false;
                        setLoading(false);
                    })
                    .fail(function (data) {
                        isScrolled = false;
                        setLoading(false);
                        if (data.status === 404) {
                            hasCaseStudies = false;
                            showNoCaseStudiesMessage();
                        }
                    });
            }
        }
    }
    /**
     * Function that fires on scroll event.
     */
    function onScroll() {
        // If is scrolled to bottom
        if (isScrolledToBottom()) {
            // Get case_studies data
            getCaseStudiesData();
        }
    }
    /**
     * Inits events.
     */
    function initEvents() {
        $(window).on('scroll', onScroll);
    }
    /**
     * Init component.
     */
    function init() {
        initEvents();
    }
    // Init
    init();
});