$(document).ready(function () {

    $('body').on('click', '.c-downloads__mobile-product', function () {
        if($(this).next('ul').hasClass('hidden')) {
            $(this).next('ul').removeClass('hidden');
            $('span.expand', this).text('-');
        } else {
            $(this).next('ul').addClass('hidden');
            $('span.expand', this).text('+');
        }

    });

    $('[data-toggle="tooltip"]').tooltip();

    function handleProductOptionCodes() {
        var options = '';

        $('#sylius-product-adding-to-cart select').each(function() {
            options += $(this).find('option:selected').val() + ' ';
        });

        var variantsWithOptions = [];
        var optionsArray = options.trim().split(' ');
        $('[data-code-options]').each(function () {
            var codeOptionsArray = $(this).attr('data-code-options').trim().split(' ');
            if(JSON.stringify(optionsArray) === JSON.stringify(codeOptionsArray)) {
                $('#variant-code').html($(this).attr('data-value'));
            }
        });
    }

    handleProductOptionCodes();

    $('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', function () {
        handleProductOptionCodes();
    });


});