/**
 * Created by torpedo on 19.07.18..
 */

$(document).ready(function () {

    $('#sylius-product-selecting-variant select').on('change', function () {
        handleProductOptionTabs();
    })

    $('#sylius-product-selecting-variant input').on('click', function () {
        handleVariantChoiceTabs();
    })

    function handleProductOptionTabs() {
        $('.variant-tabs').addClass('hidden');
        var options = '';
        $('#sylius-product-selecting-variant select').each(function() {
            options += $(this).find('option:selected').val() + ' ';
        });
        $('[data-tab-parent-name]').each(function () {
            $(this).removeClass('hidden');
        });
        $('[data-variant-options]').each(function () {
            if (options == $(this).attr('data-variant-options')) {
                var tabName = $(this).attr('data-tab-name');
                $('[data-tab-parent-name]').each(function () {
                    if (tabName == $(this).attr('data-tab-parent-name')) {
                        $(this).addClass('hidden');
                    }
                })
                $(this).removeClass('hidden');
            }
        });
    }

    function handleVariantChoiceTabs() {
        $('.variant-tabs').addClass('hidden');

        var options = '';

        $("input[name='sylius_add_to_cart[cartItem][variant]']:checked").parent().parent().find('.option-code').each(function() {
            options += $(this).text().trim() + ' ';
        })

        $('[data-tab-parent-name]').each(function () {
            $(this).removeClass('hidden');
        });
        $('[data-variant-options]').each(function () {
            if (options == $(this).attr('data-variant-options')) {
                var tabName = $(this).attr('data-tab-name');
                $('[data-tab-parent-name]').each(function () {
                    if (tabName == $(this).attr('data-tab-parent-name')) {
                        $(this).addClass('hidden');
                    }
                })
                $(this).removeClass('hidden');
            }
        });
    }


    handleProductOptionTabs();
    handleVariantChoiceTabs();

});